<template>
  <div>
    <div>
      <p
        class="flex items-center mb-5 text-lightGrey-2 cursor-pointer"
        @click="$emit('back')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
        >
          <path
            d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.0867 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
            fill="#6B6873"
          />
        </svg>
        <span class="ml-3 text-sm">Back</span>
      </p>
      <h4 v-if="code.length === 0" class="text-xl font-bold">
        Create a payment by using the Merchant ID
      </h4>
      <h4 v-else-if="code.length === 6" class="text-xl font-bold">
        Create a payment to {{ name }}
      </h4>
      <Input
        v-if="code.length === 0"
        placeholder="Merchant ID"
        width="w-full"
        class="mt-6"
        type="number"
        v-model="order.merchantTag"
        errorText="Merchant ID must be 6 digits"
        :validation="rules.merchantTag"
      />
      <Input
        placeholder="Order title"
        width="w-full"
        class="mt-6"
        v-model="order.title"
        errorText="Please enter an order title"
        :validation="rules.title"
      />
      <CurrencyInput
        placeholder="Amount"
        width="w-full"
        class="mt-6"
        v-model="order.amount"
        errorText="Amount must be at lease ₦100"
        :validation="rules.amount"
      />
      <Button
        width="w-full"
        text="Proceed"
        class="mt-12"
        :loading="loading"
        type="submit"
        @click="handleCheckout"
        :disabled="disableButton"
      />
    </div>
    <CheckoutIframe
      v-if="showIframe"
      :paymentLink="paymentLink"
      @close="
        showIframe = false;
        loading = false;
      "
      @success="handleSuccess"
    />
  </div>
</template>
<script>
import { Button } from "@/UI/Button";
import { Input, CurrencyInput } from "@/UI/Input";
import { CUSTOMER_CREATE_ORDER } from "@/utils/api/checkout";
export default {
  components: {
    Button,
    CurrencyInput,
    Input,
    CheckoutIframe: () => import("./CheckoutIframe.vue"),
  },
  data: () => ({
    order: {
      merchantTag: "",
      amount: 0,
      title: "",
    },
    loading: false,
    showIframe: false,
    paymentLink: "",
  }),
  props: {
    code: {
      type: String,
      default: "",
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    rules() {
      return {
        merchantTag: this.order.merchantTag.length === 6,
        amount: this.order.amount > 100,
        title: this.order.title.length > 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.order.merchantTag = this.code;
  },
  methods: {
    handleCheckout() {
      this.loading = true;
      CUSTOMER_CREATE_ORDER(this.order)
        .then(({ data }) => {
          this.paymentLink = data.data.paymentLink;
          this.showIframe = true;
        })
        .catch((err) => {
          this.loading = false;
          if (err?.response?.status === 404) {
            this.$store.dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: err.response.data.message,
              },
              { root: true }
            );
          }
        });
    },
    handleSuccess(e) {
      this.showIframe = false;
      this.$emit("success", e);
    },
  },
};
</script>
